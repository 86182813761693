<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { createRequired } from '~/helpers/i18n-validators'
import RInput from '~/components/rInput.vue'
import RButton from '~/components/rButton.vue'
import { useMetrika } from '~/composables/useMetrika.client'
import { useAuthUser } from '~/composables/auth/useAuthUser'

const user = useAuthUser()
const { $toast, $i18n } = useNuxtApp()
const { required, email, minLength } = createRequired($i18n)

useHead({
  title: 'Авторизация | Ранг',
})

const router = useRouter()
definePageMeta({
  layout: 'only-header',
  middleware: ['guest-only'],
})
const { login } = useAuth()
const rules = computed(() => {
  return {
    email: { required, email },
    password: { required, minLength },
  }
})

const form = reactive({
  email: null,
  password: null,
})
const v$ = useVuelidate(rules, form)

function resetForm() {
  v$.value.$reset()
}
const loading = ref(false)

async function submitForm() {
  loading.value = true
  try {
    v$.value.$validate()
    if (!v$.value.$error) {
      await login(form)
      router.push('/')
      await useMetrika('login', { userId: user.value.id })
    }
  }
  catch (e: unknown) {
    if (e instanceof Error)
      $toast.error(e.data?.message || 'Упс, произошла ошибка')
  }
  finally {
    loading.value = false
  }
}
</script>

<template>
  <div class="mx-32 mt-24">
    <div class="flex flex-col">
      <h1 class="text-h1 mb-10">
        Вход в Ранг
      </h1>
      <form
        class="flex flex-col gap-8 w-full"
        novalidate
        @submit.prevent="submitForm()"
      >
        <RInput
          v-model:value="form.email"
          type="email"
          label="Введите email"
          placeholder="example@mail.ru"
          icon="mdi:mail-outline"
          :validation="v$.email"
          @reset-form="resetForm"
        />
        <RInput
          v-model:value="form.password"
          type="password"
          label="Введите пароль"
          placeholder="•••••••••"
          icon="uil:lock"
          :validation="v$.password"
          @reset-form="resetForm"
        />
        <div class="flex items-center gap-6">
          <RButton type="submit" :is-loading="loading" color="primary">
            Войти
          </RButton>
          <div class="flex items-center justify-center">
            <NuxtLink
              no-prefetch
              to="/forgot"
              class="self-end cursor-pointer hover:border-blue-400 border-b border-blue-500 text-blue-500 text-primary-small"
            >
              Забыли пароль?
            </NuxtLink>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
